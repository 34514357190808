/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../dataProvider'
import { t } from '../../../t'


const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}


class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterName: '',
            filterUrl: '',

            loading: false
        }
    }

    componentDidMount() {
        this.call()
    }

    call() {
        dataProvider.getList('Website', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { name: this.state.filterName, url: this.state.filterUrl } })
            .then(response => this.setState({ data: response.data }))
    }

    submit() {
        this.setState({ loading: true }, () => {
            const error = {}
            // if (!this.state.name)
            //     error.name = true
            if (!this.state.url)
                error.url = true
            if (Object.keys(error).length === 0) {
                dataProvider.create('Website', {
                    data: {
                        // name: this.state.name,
                        url: this.state.url,
                    }
                }).then(() => window.location = '/website')
            } else {
                this.setState({ error, loading: false })
            }
        })
    }

    renderList() {
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <Col lg="12">
                <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <h4 className="font-weight-bold">{t("Website")}</h4>
                    </div>
                    <div className="create-workform">
                        <div className="d-flex flex-wrap align-items-center justify-content-between">
                            <div className="modal-product-search d-flex">
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                        <Form.Control type="text" className="form-control" id="exampleInputText" placeholder={t("Search Website")} onChange={(e) => this.setState({ filterName: e.target.value }, () => this.call())} />
                                        {/* <Link className="search-link" to="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </Link> */}
                                    </div>
                                </Form>
                                <Form className="mr-3 position-relative">
                                    <div className="form-group mb-0">
                                        <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Url" onChange={(e) => this.setState({ filterUrl: e.target.value }, () => this.call())} />
                                    </div>
                                </Form>
                                {/* <Link to="/website-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Add Website
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col lg="12">
                        <Card className="card-block card-stretch">
                            <Card.Body className="p-0">
                                <div className="d-flex justify-content-between align-items-center p-3">
                                    <h5 className="font-weight-bold">{t("Websites List")}</h5>
                                    {/* <Button variant="btn btn-secondary btn-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                        </svg>
                        Export
                    </Button> */}
                                </div>
                                <div className="table-responsive">
                                    <table className="table data-table mb-0">
                                        <thead className="table-color-heading">
                                            <tr className="text-light">
                                                <th scope="col" style={{ cursor: "pointer" }} onClick={sort("name")}>
                                                    <label className="text-muted m-0" style={{ cursor: "pointer" }} >{t("Name")}</label>
                                                    {' '}
                                                    {this.state.field === "name" && this.state.order === 'desc' && (down)}
                                                    {this.state.field === "name" && this.state.order === 'asc' && (up)}
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >{t("Url")}</label>
                                                </th>
                                                <th scope="col">
                                                    <label className="text-muted mb-0" >{t("Connection")}</label>
                                                </th>
                                                {/* <th scope="col">
                                                </th> */}
                                                <th scope="col">
                                                </th>
                                                <th scope="col">
                                                </th>
                                                <th scope="col">
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.data.map((item) => (
                                                    <tr key={item.Price} className="white-space-no-wrap">
                                                        <td className="">
                                                            <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                {/* <div className="h-avatar is-medium">
                                                                <img className="avatar rounded" alt="user-icon" src={item.product} />
                                                            </div> */}
                                                                <div className="data-content">
                                                                    <div>
                                                                        <span className="font-weight-bold">{item.name}</span>
                                                                    </div>
                                                                    {/* <p className="m-0 mt-1">
                                                                    {item.des}
                                                                </p> */}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><a href={item.url}>{item.url}</a></td>
                                                        <td style={item.connection.type === 'success' ? { color: 'green' } : (item.connection.type === 'error' ? { color: 'red' } : {})}>
                                                            {item.connection.type === 'success' && (
                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                    <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle>
                                                                </svg>
                                                            )}
                                                            {item.connection.type === 'error' && (
                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                    <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
                                                                </svg>
                                                            )}
                                                            {t(item.connection.message)}
                                                        </td>
                                                        {/* <td>
                                                            <ConnectionIsIndexed isindexed={item.J} />
                                                        </td> */}

                                                        {/* <td>
                                                        <p className={`mb-0 ${item.color} font-weight-bold d-flex justify-content-start align-items-center`}>
                                                            <small><svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle></svg>
                                                            </small>{item.Inventory}
                                                        </p>
                                                    </td> */}
                                                        {/* <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Test Connection")}</Tooltip>} >
                                                                    <Link className="" onClick={() => dataProvider.customs.testWebsiteWp(item.id).then(() => window.location.reload())}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M5 12a1 1 0 102 0V6.414l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L5 6.414V12zM15 8a1 1 0 10-2 0v5.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L15 13.586V8z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td> */}
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Edit")}</Tooltip>} >
                                                                    <Link to={"/website-edit?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                                                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("View")}</Tooltip>} >
                                                                    <Link to={"/website-view?id=" + item.id} className="">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex justify-content-end align-items-center">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Delete")}</Tooltip>} >
                                                                    <Link className="badge bg-danger" onClick={() => dataProvider.delete('Website', { id: item.id }).then(() => window.location.reload())}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                        </svg>
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>

        )
    }

    renderAdd() {
        return (
            <Row>
                <Col lg="12" className="mb-3 d-flex justify-content-between">
                    <h4 className="font-weight-bold d-flex align-items-center">{t("New Website")}</h4>
                </Col>
                <Col lg="12">
                    <Card>
                        <Card.Body>
                            {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                            <Form className="row g-3">
                                <div className="col-md-6 mb-3">
                                    <div>
                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("URL")} *</Form.Label>
                                    </div>
                                    <div>
                                        <Form.Control type="text" id="Text1" placeholder={t("Enter URL")} onChange={e => this.setState({ url: e.target.value })} style={this.state.error && this.state.error.url ? { border: "1px solid red" } : {}} />
                                    </div>

                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className='d-flex justify-content-end mt-3' style={{ paddingTop: "10px" }}>
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    {t("Create Website")}
                                                </Button>
                                            )
                                        }
                                        <Button style={{ marginLeft: "10px" }} variant="btn btn-primary" onClick={() => window.open('https://app.bradigo.fr/bradigo-1.0.0.zip', '_blank')}>
                                            {t("Download Plugin")}
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body className="col-md-6">
                            <div>
                                {t("You have to install our")}{' '}<a href="https://app.bradigo.fr/bradigo-1.0.0.zip" target="_blank" rel="noopener">{t("Plugin")}</a>
                            </div>
                            <div>
                                {t("How to link your site to webapp ?")}{' '}<a href='/tuto'>{t("Click here")}</a>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    render() {
        if (!this.state.data)
            return <></>

        return (
            <>
                <Container fluid>
                    <Row>
                        <Card.Body className="p-0">
                            {/* <Tab.Container defaultActiveKey="pills-first-tab-fill">
                                <Nav variant="pills mb-3" fill>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-first-tab-fill">Websites</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-second-tab-fill">Add website</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="pills-first-tab-fill" className="fade p-3">
                                        {this.renderList()}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pills-second-tab-fill" className="fade p-3">
                                        {this.renderAdd()}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container> */}
                            {this.renderList()}
                            {this.renderAdd()}
                        </Card.Body>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
