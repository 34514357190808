/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, Tab, Nav, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../dataProvider'
import { t } from '../../../t'


const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class MyVerticallyCenteredModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            interval: 0
        }
    }

    checkDate() {
        const d = new Date(this.state.date || this.nowString())
        if (this.state.website && Date.now() - 24 * 3600 * 1000 <= d.getTime()) {
            this.setState({ ok: true })
        } else {
            this.setState({ ok: false })
        }
        if (this.state.website) {
            const website = this.props.websites.filter(e => e.id === parseInt(this.state.website))[0]
            this.setState({
                categories: website.wp_categories,
                users: website.wp_authors
            })
        }
    }

    nowString() {
        const d = new Date()
        return `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}-${d.getDate() < 10 ? '0' : ''}${d.getDate()}`
    }

    submit() {
        this.setState({ loading: true }, async () => {
            if (this.props.idData) {
                dataProvider.customs.linkPostInTask(this.props.idData, this.state.website, this.state.date, this.state.category, this.state.user).then(() => window.location.reload())
            } else {
                for (let i = 0; i < this.props.selected.length; i++) {
                    let date = new Date(this.state.date || this.nowString())
                    date.setTime(date.getTime() + i * this.state.interval * 24 * 3600 * 1000)
                    date = date.toISOString().split('T')[0]
                    await dataProvider.customs.linkPostInTask(this.props.selected[i], this.state.website, date, this.state.category, this.state.user)
                }
                setTimeout(() => window.location.reload(), 1000)
            }
        })
    }

    loadCategories() {
        dataProvider.customs.websiteWordpressCategories(this.state.website).then(json => {
            this.setState({
                categories: json.data
            })
        })
    }

    loadUsers() {
        dataProvider.customs.websiteWordpressUsers(this.state.website).then(json => {
            this.setState({
                users: json.data
            })
        })
    }

    render() {
        const props = this.props
        console.log({ props })
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("Configurate Publication")}
                    </Modal.Title>
                </Modal.Header>
                <Card>
                    <Card.Body>
                        <Form className="row g-3">
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Publication Date")} *</Form.Label>
                                <Form.Control type="date" min={this.nowString()} className="form-control" id="Text1" value={this.state.date || this.nowString()} onChange={e => this.setState({ date: e.target.value }, () => this.checkDate())} style={this.state.error && this.state.error.date ? { border: "1px solid red" } : {}} />
                            </div>
                        </Form>
                    </Card.Body>
                    {
                        !this.props.idData && (
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Interval")}</Form.Label>
                                <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ interval: e.target.value })} style={this.state.error && this.state.error.interval ? { border: "1px solid red" } : {}}>
                                    <option value={0} selected>{t("0 day")}</option>
                                    <option value={1} >{t("1 day")}</option>
                                    <option value={2} >{t("2 day")}</option>
                                    <option value={3} >{t("3 day")}</option>
                                    <option value={4} >{t("4 day")}</option>
                                    <option value={5} >{t("5 day")}</option>
                                    <option value={6} >{t("6 day")}</option>
                                    <option value={7} >{t("7 day")}</option>
                                    <option value={14} >{t("14 day")}</option>
                                    <option value={30} >{t("30 day")}</option>
                                    <option value={60} >{t("60 day")}</option>
                                    <option value={90} >{t("90 day")}</option>
                                </select>
                            </div>
                        )
                    }
                    {
                        props.websites && (
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Websites")}</Form.Label>
                                <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ website: e.target.value }, () => this.checkDate())} style={this.state.error && this.state.error.website ? { border: "1px solid red" } : {}}>
                                    <option value="" selected>{t("Select Websites")}</option>
                                    {
                                        props.websites.filter(elem => elem.configured).map(e => <option value={e.id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        )
                    }
                    {
                        this.state.website && !this.state.categories && (
                            <Card.Body>
                                <Button onClick={() => this.loadCategories()}>{t("Load categories From Website")}</Button>
                            </Card.Body>
                        )
                    }
                    {
                        this.state.categories && (
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Category")}</Form.Label>
                                <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}}>
                                    <option value="" selected>{t("Select Category")}</option>
                                    {
                                        this.state.categories.map(e => <option value={e.id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        )
                    }
                    {
                        this.state.website && !this.state.users && (
                            <Card.Body>
                                <Button onClick={() => this.loadUsers()}>{t("Load Authors From Website")}</Button>
                            </Card.Body>
                        )
                    }
                    {
                        this.state.users && (
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Author")}</Form.Label>
                                <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ user: e.target.value })} style={this.state.error && this.state.error.user ? { border: "1px solid red" } : {}}>
                                    <option value="" selected>{t("Select Author")}</option>
                                    {
                                        this.state.users.map(e => <option value={e.id}>{e.name}</option>)
                                    }
                                </select>

                            </div>
                        )
                    }
                </Card>
                <Modal.Footer>
                    {
                        <div style={{ display: "flex", height: "20px" }}>
                            {t("Cela peut prendre plusieurs minutes")}
                        </div>
                    }
                    {
                        this.state.loading && (
                            <div style={{ display: "flex", height: "20px" }}>
                                {t("Loading...")}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        )
                    }
                    {
                        !this.state.loading && (
                            <Button disabled={!this.state.ok} onClick={() => this.submit()}>{t("Submit")}</Button>
                        )
                    }
                    {
                        !this.state.loading && (
                            <Button onClick={props.onHide}>{t("Close")}</Button>
                        )
                    }

                </Modal.Footer>
            </Modal>
        );
    }
}

const keyFromDate = (d) => {
    d = new Date(d)
    return `${d.getDate() < 10 ? '0' : ''}${d.getDate()}/${d.getMonth() + 1 < 10 ? '0' : ''}${d.getMonth() + 1}/${d.getFullYear()}`
}

class ConnectionIsIndexed extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    submit(link) {
        this.setState({ loading: true }, () => {
            dataProvider.customs.indexmenow(link).then(() => window.location.reload() /*this.setState({ loading: false, force: true })*/)
        })
    }

    render() {

        const green = (
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle>
            </svg>
        )
        const red = (
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
            </svg>
        )
        const grey = (
            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="8" fill="#888"></circle>
            </svg>
        )
        return (
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <OverlayTrigger placement="top" overlay={<Tooltip>{keyFromDate(new Date(this.props.post.published_date).getTime() + 1 * 24 * 3600 * 1000)}</Tooltip>} >
                    <div style={{ width: "18px" }}>
                        {this.props.post.J_1 && this.props.post.J_1 == "1" && green}
                        {this.props.post.J_1 && this.props.post.J_1 != "1" && red}
                        {!this.props.post.J_1 && grey}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{keyFromDate(new Date(this.props.post.published_date).getTime() + 3 * 24 * 3600 * 1000)}</Tooltip>} >
                    <div style={{ width: "18px" }}>
                        {this.props.post.J_3 && this.props.post.J_3 == "1" && green}
                        {this.props.post.J_3 && this.props.post.J_3 != "1" && red}
                        {!this.props.post.J_3 && grey}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{keyFromDate(new Date(this.props.post.published_date).getTime() + 7 * 24 * 3600 * 1000)}</Tooltip>} >
                    <div style={{ width: "18px" }}>
                        {this.props.post.J_7 && this.props.post.J_7 == "1" && green}
                        {this.props.post.J_7 && this.props.post.J_7 != "1" && red}
                        {!this.props.post.J_7 && grey}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{keyFromDate(new Date(this.props.post.published_date).getTime() + 15 * 24 * 3600 * 1000)}</Tooltip>} >
                    <div style={{ width: "18px" }}>
                        {this.props.post.J_15 && this.props.post.J_15 == "1" && green}
                        {this.props.post.J_15 && this.props.post.J_15 != "1" && red}
                        {!this.props.post.J_15 && grey}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{keyFromDate(new Date(this.props.post.published_date).getTime() + 30 * 24 * 3600 * 1000)}</Tooltip>} >
                    <div style={{ width: "18px" }}>
                        {this.props.post.J_30 && this.props.post.J_30 == "1" && green}
                        {this.props.post.J_30 && this.props.post.J_30 != "1" && red}
                        {!this.props.post.J_30 && grey}
                    </div>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Cette action coute 1 credit")}</Tooltip>} >
                    <div style={{ marginLeft: "10px" }}>
                        {
                            (this.state.force || this.props.post.indexmenow) && (
                                <>{'✅'}</>
                            )
                        }
                        {
                            !(this.state.force || this.props.post.indexmenow) && !this.state.loading && (
                                <button disabled={this.props.profile.credits === 0} className="btn text-primary bg-primary-light btn-sm" onClick={() => this.submit(this.props.post.links[0])}>
                                    {t("Forcer l'indexation")}
                                </button>
                            )
                        }
                        {
                            this.state.loading && (
                                <div style={{ display: "flex", height: "20px" }}>
                                    {t("Loading...")}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                                    </svg>
                                </div>
                            )
                        }
                    </div>
                </OverlayTrigger>
            </div>
        )
    }
}

class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: 'createdAt',
            order: 'desc',
            filterTitle: '',
            filterCategory: '',
            loadingBuy: false,
            idData: -1,
            selected: [],
        }

        this.categories = [
            "Animaux",
            "Art & Culture",
            "Avocat & Juridique",
            "Beauté",
            "Code Promo",
            "Croyance & Voyance",
            "Entreprise",
            "Fêtes & Cérémonies",
            "Finance & Banque",
            "Cuisine & Gastronomie",
            "Généraliste",
            "Écologie",
            "Hightech & Informatique",
            "Immo & Démenagement",
            "Jeux d'argent",
            "Loisir créatif",
            "Maison & Jardin",
            "Mode",
            "Parentalité",
            "Rencontre",
            "Sexe",
            "Retraite",
            "Santé",
            "Science",
            "Sport & Loisir",
            "Tabac",
            "Transport",
            "Travaux & Dépannage",
            "Voyage & Hôtellerie",
            "Non classé",
        ]
    }

    componentDidMount() {
        this.call()
        dataProvider.customs.profile().then((response) => {
            this.setState({ profile: response.data })
        })
        dataProvider.getList('Partner', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { title: this.state.filterTitle, category: this.state.filterCategory, state: this.state.filterState } })
            .then(response => this.setState({ partners: response.data.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}) }))
    }

    call() {
        dataProvider.getList('PostOrder', {
            pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: {
                title: this.state.filterTitle, category: this.state.filterCategory,
                score_NextLevel: this.state.score_NextLevel,
                // score_Linksgarden: this.state.score_Linksgarden,
                score_Discover: this.state.score_Discover,
                score_Amazon: this.state.score_Amazon,
                has_image: this.state.has_image,
            }
        })
            .then(response => this.setState({ data: response.data }))
        dataProvider.getList('Website', { pagination: { page: 1, perPage: 10000 }, sort: { field: null, order: null }, filter: {} })
            .then(response => this.setState({ websites: response.data }))
    }

    getWebsite(website) {
        if (!this.state.websites)
            return website
        return (this.state.websites.filter(e => e.id === website)[0] || {}).name
    }

    renderList(filter, hasActionLink, hasPublicationDate, hasWebsite, hasIndexed) {
        if (!this.state.data)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        const data = this.state.data.filter(filter).filter(e => {
            if (!hasWebsite || !this.state.filterWebsite)
                return true
            if (hasPublicationDate) {
                if (Object.values(e.websites).filter(e => !!e.published_date)[0].website === parseInt(this.state.filterWebsite))
                    return true
            }
            if (!hasPublicationDate) {
                if (Object.values(e.websites).filter(e => !!e.wordpress_post_id)[0].website === parseInt(this.state.filterWebsite))
                    return true
            }
            return false
        })

        const availableCats = data.map(e => e.category)

        return (
            <>

                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold">{t("Mes commandes")}</h4>
                                </div>
                                <div className="create-workform">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="modal-product-search d-flex">
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder={t("Search Product")} onChange={(e) => this.setState({ filterTitle: e.target.value }, () => this.call())} />
                                                    {/* <Link className="search-link" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                    </Link> */}
                                                </div>
                                            </Form>
                                            {/* <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder="Category" onChange={(e) => this.setState({ filterCategory: e.target.value }, () => this.call())} />
                                                </div>
                                            </Form> */}
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0" style={{ display: "flex", cursor: "pointer" }} >
                                                    {
                                                        this.state.filterCategory && (
                                                            <div style={{ paddingTop: "8px" }} onClick={() => this.setState({ filterCategory: '' }, () => this.call())} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </div>
                                                        )
                                                    }
                                                    <select className="form-control choicesjs" id="validationServer01" placeholder="Category" value={this.state.filterCategory} onChange={(e) => this.setState({ filterCategory: e.target.value }, () => this.call())} >
                                                        <option value="" selected>{t("Select Category")}</option>
                                                        {
                                                            this.categories.filter(e => availableCats.includes(e)).map(e => <option value={e}>{e}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </Form>
                                            {
                                                hasWebsite && (
                                                    <div className="col-md-6 mb-3">
                                                        <select className="form-control choicesjs" id="validationServer01" placeholder="Website" value={this.state.filterWebsite} onChange={e => this.setState({ filterWebsite: e.target.value })} style={this.state.error && this.state.error.website ? { border: "1px solid red" } : {}}>
                                                            <option value="" selected>{t("Select Website")}</option>
                                                            {
                                                                (this.state.websites || []).map(e => <option value={e.id}>{e.name}</option>)
                                                            }
                                                        </select>
                                                    </div>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card className="card-block card-stretch">
                                        <Card.Body className="p-0">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5 className="font-weight-bold">{t("Liste des articles")}</h5>
                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0" >
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >{t("Partner")}</label>
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("title")}>
                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >{t("Title")}</label>
                                                                {' '}
                                                                {this.state.field === "title" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "title" && this.state.order === 'asc' && (up)}
                                                                {/* <input type="text" className="form-control mt-2" id="text1" aria-describedby="textHelp" placeholder={t("Search Product")}>  */}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" ></label>
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" >{t("Category")}</label>
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("count_words")}>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Count words")}</Tooltip>} >
                                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Words")}</label>
                                                                </OverlayTrigger>
                                                                {' '}
                                                                {this.state.field === "count_words" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "count_words" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            {/* <th scope="col" style={{ cursor: "pointer" }} onClick={sort("spam_score")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Spam Score</label>
                                                                {' '}
                                                                {this.state.field === "spam_score" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "spam_score" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("scoreYTG")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Score YTG</label>
                                                                {' '}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'asc' && (up)}
                                                            </th> */}
                                                            {
                                                                hasPublicationDate && (
                                                                    <th scope="col" >
                                                                        <label className="text-muted mb-0" >{t("Publication")}</label>
                                                                    </th>
                                                                )
                                                            }
                                                            {
                                                                hasIndexed && (
                                                                    <th scope="col" >
                                                                        <label className="text-muted mb-0" >{t("Publication")}</label>
                                                                    </th>
                                                                )
                                                            }
                                                            {
                                                                hasWebsite && (
                                                                    <th scope="col" >
                                                                        <label className="text-muted mb-0" >{t("Website")}</label>
                                                                    </th>
                                                                )
                                                            }
                                                            {
                                                                hasIndexed && (
                                                                    <th scope="col" >
                                                                        <label className="text-muted mb-0" >{t("Indexation")}</label>
                                                                    </th>
                                                                )
                                                            }
                                                            {
                                                                !hasIndexed && (
                                                                    <th scope="col" style={{ cursor: "pointer", textAlign: "center" }} onClick={sort("scoreYTG")}>
                                                                        <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Caractéristique")}</label>
                                                                        {' '}
                                                                        {this.state.field === "scoreYTG" && this.state.order === 'desc' && (down)}
                                                                        {this.state.field === "scoreYTG" && this.state.order === 'asc' && (up)}
                                                                    </th>
                                                                )
                                                            }
                                                            <th scope="col">
                                                            </th>
                                                            <th scope="col">
                                                            </th>
                                                            {
                                                                hasActionLink && (<th>
                                                                    {
                                                                        this.state.selected.length > 0 && (
                                                                            <div style={{ cursor: "pointer", color: "#3378FF" }} onClick={() => this.setState({ idData: 0 })} >
                                                                                Bulk
                                                                                {' '}
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                </svg>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    {
                                                                        this.state.selected.length === 0 && (
                                                                            <div style={{ color: "#888888" }} >
                                                                                Bulk
                                                                                {' '}
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                </svg>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </th>)
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.map((item, index) => (
                                                                <tr key={item.Price} className="white-space-no-wrap">
                                                                    <td>
                                                                        {
                                                                            item.partner ? (<img style={{ width: "50px", height: "50px", borderRadius: "25px" }} src={this.state.partners[item.partner].avatar} /> || this.state.partners[item.partner].name) : "Bradigo"
                                                                        }
                                                                    </td>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            {/* <div className="h-avatar is-medium">
                                                                                <img className="avatar rounded" alt="user-icon" src={item.product} />
                                                                            </div> */}
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">
                                                                                        {item.links.length > 0 &&
                                                                                            <a href={item.links[0]} target="_blank">
                                                                                                {item.title}
                                                                                                {' '}
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                                                                                </svg>
                                                                                            </a>
                                                                                        }
                                                                                        {item.links.length === 0 && <>{item.title}</>}
                                                                                    </span>
                                                                                </div>
                                                                                {/* <p className="m-0 mt-1">
                                                                                    {item.des}
                                                                                </p> */}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {item.tag_human_robot === 'human' && <img src='/100_Humain.png' style={{ width: "40px" }} />}
                                                                        {item.tag_human_robot === 'human-robot' && <img src='/50_50.png' style={{ width: "40px" }} />}
                                                                        {item.tag_human_robot === 'robot' && <img src='/100_AI.png' style={{ width: "40px" }} />}
                                                                    </td>
                                                                    <td>{item.category}</td>
                                                                    <td>
                                                                        {item.count_words}
                                                                    </td>
                                                                    {/* <td>
                                                                        {item.spam_score}
                                                                    </td>
                                                                    <td>
                                                                        {item.scoreYTG}
                                                                    </td> */}
                                                                    {
                                                                        hasPublicationDate && (
                                                                            <th scope="col" >
                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{Object.values(item.websites).filter(e => !!e.published_date)[0].published_date.split('T')[0]}</Tooltip>} >
                                                                                    <label className="text-muted mb-0" >{t('In XXX day(s)').split('XXX').join(Math.floor((new Date(Object.values(item.websites).filter(e => !!e.published_date)[0].published_date).getTime() - Date.now()) / (24 * 3600 * 1000)) + 1)}</label>
                                                                                </OverlayTrigger>
                                                                            </th>
                                                                        )
                                                                    }
                                                                    {
                                                                        hasIndexed && (
                                                                            <th scope="col" >
                                                                                <label className="text-muted mb-0" >{
                                                                                    (() => {
                                                                                        const a = Object.values(item.websites).filter(e => !!e.published_date)[0]
                                                                                        if (a)
                                                                                            return formatDate(a.published_date)
                                                                                        return '-'
                                                                                    })()
                                                                                }</label>
                                                                            </th>
                                                                        )
                                                                    }
                                                                    {
                                                                        hasWebsite && (
                                                                            <th scope="col" >
                                                                                <div style={{ cursor: "pointer" }} onClick={() => {
                                                                                    let website = null
                                                                                    if (hasPublicationDate) {
                                                                                        website = Object.values(item.websites).filter(e => !!e.published_date)[0].website
                                                                                    } else {
                                                                                        website = Object.values(item.websites).filter(e => !!e.wordpress_post_id)[0].website
                                                                                    }
                                                                                    console.log(website, this.state.filterWebsite)
                                                                                    this.setState({ filterWebsite: website === this.state.filterWebsite ? '' : website })
                                                                                }} >

                                                                                    {/* <label className="text-muted mb-0" > */}
                                                                                    {hasPublicationDate && this.getWebsite(Object.values(item.websites).filter(e => !!e.published_date)[0].website)}
                                                                                    {!hasPublicationDate && this.getWebsite(Object.values(item.websites).filter(e => !!e.wordpress_post_id)[0].website)}
                                                                                    {/* </label> */}
                                                                                </div>
                                                                            </th>
                                                                        )
                                                                    }
                                                                    {
                                                                        hasIndexed && (
                                                                            <td>
                                                                                {
                                                                                    item.links.length > 0 && (
                                                                                        <ConnectionIsIndexed post={item} profile={this.state.profile} />
                                                                                    )
                                                                                }
                                                                            </td>
                                                                        )
                                                                    }
                                                                    {
                                                                        !hasIndexed && (
                                                                            <td>
                                                                                <div>
                                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("Score d'optimisiation & Danger SEO")}</Tooltip>} >
                                                                                        {
                                                                                            item.scoreYTG ?
                                                                                                <span style={{ position: 'relative' }}>
                                                                                                    <img style={{ width: "98px" }} src='/YourTextGuru.png' />
                                                                                                    <div style={{ position: "absolute", top: "1px", left: "10px" }}>{item.scoreYTG}</div>
                                                                                                    <div style={{ position: "absolute", top: "1px", right: "10px" }}>{item.spam_score}</div>
                                                                                                </span>
                                                                                                :
                                                                                                <span style={{ position: 'relative' }}>
                                                                                                    <img style={{ width: "98px" }} src='/YourTextGuru_off.png' />
                                                                                                </span>
                                                                                        }

                                                                                    </OverlayTrigger>
                                                                                    {' '}
                                                                                    {item.score_NextLevel && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur NextLevel")}</Tooltip>} ><span onClick={() => this.setState({ score_NextLevel: this.state.score_NextLevel ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/NextLevelLink.png' /></span></OverlayTrigger>}
                                                                                    {!item.score_NextLevel && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur NextLevel")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/NextLevelLink_off.png' /></span></OverlayTrigger>}
                                                                                    {/* {' '}
                                                                                    {item.score_Linksgarden && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur Linksgarden")}</Tooltip>} ><span onClick={() => this.setState({ score_Linksgarden: this.state.score_Linksgarden ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Linksgarden.png' /></span></OverlayTrigger>}
                                                                                    {!item.score_Linksgarden && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur Linksgarden")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Linksgarden_off.png' /></span></OverlayTrigger>} */}
                                                                                    {' '}
                                                                                    {item.score_Discover && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Optimisé pour Discover")}</Tooltip>} ><span onClick={() => this.setState({ score_Discover: this.state.score_Discover ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Discover.png' /></span></OverlayTrigger>}
                                                                                    {!item.score_Discover && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Optimisé pour Discover")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Discover_off.png' /></span></OverlayTrigger>}
                                                                                    {' '}
                                                                                    {item.score_Amazon && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Adapté pour Amazon")}</Tooltip>} ><span onClick={() => this.setState({ score_Amazon: this.state.score_Amazon ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Amazon.png' /></span></OverlayTrigger>}
                                                                                    {!item.score_Amazon && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Adapté pour Amazon")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Amazon_off.png' /></span></OverlayTrigger>}
                                                                                    {' '}
                                                                                    {item.has_image && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Contient minimum 1 image")}</Tooltip>} ><span onClick={() => this.setState({ has_image: this.state.has_image ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Image.png' /></span></OverlayTrigger>}
                                                                                    {!item.has_image && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Contient minimum 1 image")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Image_off.png' /></span></OverlayTrigger>}
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    }
                                                                    <td>
                                                                        <OverlayTrigger placement="top" overlay={<Tooltip>{t("View")}</Tooltip>} >
                                                                            <Link className="" to={"/order-view?id=" + item.id} >
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                </svg>
                                                                            </Link>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            hasActionLink && (
                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Link to website")}</Tooltip>} >
                                                                                    <div style={{ cursor: "pointer", color: "#3378FF" }} onClick={() => this.setState({ idData: item.id })} >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </OverlayTrigger>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    {
                                                                        hasActionLink && (
                                                                            <td className="pr-0 ">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <input type="checkbox" className="custom-control-input m-0" id={`customCheck-${item.id}`} checked={this.state.selected.includes(item.id)} onClick={() => this.setState({ selected: this.state.selected.includes(item.id) ? this.state.selected.filter(e => e !== item.id) : [...this.state.selected, item.id] })} />
                                                                                    <label className="custom-control-label" htmlFor={`customCheck-${item.id}`}></label>
                                                                                </div>
                                                                            </td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            ))

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container >
            </>
        )
    }

    render() {
        if (!this.state.data || !this.state.profile || !this.state.partners)
            return <></>
        const tab = Object.fromEntries((new URLSearchParams(window.location.search).entries())).tab

        return (
            <>
                <MyVerticallyCenteredModal
                    data={this.state.data}
                    websites={this.state.websites}
                    idData={this.state.idData}
                    selected={this.state.selected}
                    show={this.state.idData !== -1}
                    onHide={() => this.setState({ idData: -1 })}
                />
                <Container fluid>
                    <Row>
                        <Card.Body className="p-0">
                            <Tab.Container defaultActiveKey={tab || "pills-contact-tab-fill"}>
                                <Nav variant="pills mb-3" fill>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-contact-tab-fill" onClick={() => this.setState({ key: 0 })}>{t("Published")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-profile-tab-fill" onClick={() => this.setState({ key: 1 })}>{t("Intented")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-home-tab-fill" onClick={() => this.setState({ key: 2 })}>{t("To Publish")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="pills-contact-tab-fill" >
                                        {/* {this.renderList((elem) => elem.list_status === "published", false, false, true, true)} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pills-profile-tab-fill">
                                        {/* {this.renderList((elem) => elem.list_status === "intented", false, true, true)} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pills-home-tab-fill" >
                                        {/* {this.renderList((elem) => !elem.list_status, true)} */}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                            {/* <Tab.Container defaultActiveKey="first">
                                <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >To Publish</Nav.Link></Nav.Item>
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >Intented</Nav.Link></Nav.Item>
                                    <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="3" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >Published</Nav.Link></Nav.Item>
                                </Nav>

                                <Tab.Content>

                                </Tab.Content>
                            </Tab.Container> */}
                        </Card.Body>
                        {
                            !this.state.key && (
                                this.renderList((elem) => elem.list_status === "published", false, false, true, true)
                            )
                        }
                        {
                            this.state.key === 1 && (
                                this.renderList((elem) => elem.list_status === "intented", false, true, true)
                            )
                        }
                        {
                            this.state.key === 2 && (
                                this.renderList((elem) => !elem.list_status, true)
                            )
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
