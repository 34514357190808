
import { stringify } from 'query-string'
import authProvider from './authProvider'
import { loadStripe } from '@stripe/stripe-js';
import { REACT_APP_API_HOSTNAME } from './constants/constants'
import { REACT_APP_STRIPE_PUBLIC_KEY } from './constants/constants'

const API_URL = REACT_APP_API_HOSTNAME

console.log({ API_URL })

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


const mapGet = {
    'Post': 'user/posts',
    'PostOrder': 'user/posts/order',
    'Website': 'user/websites',
    'Cart': 'user/cart',
    'Invoices': 'user/invoices',
    'Partner': 'user/partners',
}

const mapPost = {
    'Website': 'user/website',
}


const fetchJson = (url, options) => {
    return fetch(url, options).then(response => response.json())
}

const dataProvider = {
    getList: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('getList', { resource, params })
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
            limit: perPage,
            offset: (page - 1) * perPage,
            sort_field: field,
            sort_order: order,
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            console.log(json)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data,
            //         total: json.total
            //     })
            // }
            resolve({
                data: json.data,
                total: json.total
            })
        })
    }),
    csv: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('csv', { resource, params })
        const query = {
            ...params.filter
        }
        const url = `${API_URL}/${mapGet[resource]}/csv?${stringify(query)}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            resolve({
                csv: json.csv,
            })
        })
    }),
    getOne: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('getOne', { resource, params })
        const url = `${API_URL}/${mapGet[resource]}?${stringify({ id: params.id })}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        fetchJson(url, { headers: requestHeaders }).then(json => {
            // json = JSON.parse(json.body)
            // if (resource === 'User') {
            //     resolve({
            //         data: json.data[0],
            //     })
            // }
            resolve({
                data: json.data[0],
            })
        })
    }),
    getMany: (resource, params) => new Promise(async (resolve) => {
        console.log('getMany', { resource, params })
        const result = []
        for (let i = 0; i < params.ids.length; i++) {
            const ret = await dataProvider.getOne(resource, { id: params.ids[i] })
            if (ret.data)
                result.push(ret.data)
        }
        console.log(result)
        resolve({ data: result })
    }),
    getManyReference: (resource, params) => new Promise(resolve => {
        console.log('getManyReference', { resource, params })
        resolve()
    }),
    create: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('create', { resource, params })
        if (resource === 'Professor') {
            // params.data.lastCrawlDate = new Date(params.data.lastCrawlDate).getTime()
        }
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.data, id: json.id } })
        })
    }),
    update: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('update', { resource, params })
        delete params.data.updatedAt
        delete params.data.createdAt
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.data } })
        })
    }),
    updateMany: (resource, params) => new Promise(resolve => {
        console.log('updateMany', { resource, params })
        resolve()
    }),
    delete: (resource, params) => new Promise(async (resolve) => {
        await authProvider.checkAuth()
        console.log('delete', { resource, params })
        const url = `${API_URL}/${mapPost[resource]}`
        const requestHeaders = new Headers()
        requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
        requestHeaders.set('Content-Type', 'application/json')
        const options = { headers: requestHeaders }
        options.method = 'DELETE'
        options.body = JSON.stringify({ id: params.id })
        fetchJson(url, options).then(json => {
            resolve({ data: { ...params.id } })
        })
    }),
    deleteMany: (resource, params) => new Promise(async (resolve) => {
        console.log('deleteMany', { resource, params })
        for (let i = 0; i < params.ids.length; i++) {
            await dataProvider.delete(resource, { id: params.ids[i] })
        }
        resolve({ data: [...params.ids] })
    }),
    customs: {
        dashboard: (start, end, website) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/dashboard?${stringify({ start, end, website })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    data: json.data,
                })
            })
        }),
        testWp: (url, api_key) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url2 = `${API_URL}/user/test/wp?${stringify({ url, api_key })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url2, { headers: requestHeaders }).then(json => {
                resolve({
                    result: json.result,
                })
            })
        }),
        testWebsiteWp: (id) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/website/test/wp?${stringify({ id })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    result: json.result,
                })
            })
        }),
        loadTodoList: () => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/todolists`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    board: json.board,
                })
            })
        }),
        saveTodoList: (board) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/todolists`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            const options = { headers: requestHeaders }
            options.method = 'POST'
            options.body = JSON.stringify({ board })
            fetchJson(url, options).then(json => {
                resolve()
            })
        }),
        profile: () => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/profile`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve(json)
            })
        }),
        buy: (price) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const stripe = await stripePromise;
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            const response = await fetch(`${API_URL}/user/create-checkout-session`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ price })
            });
            const session = await response.json();
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
            }

            resolve()
        }),
        buyPost: (post) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/post/buy`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ post })
            })
            resolve()
        }),
        deleteBuyPost: (post) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/post/buy`, {
                headers: requestHeaders,
                method: 'DELETE',
                body: JSON.stringify({ post })
            })
            resolve()
        }),
        buyCart: () => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            const json = await fetchJson(`${API_URL}/user/cart/buy`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({})
            })
            resolve(json)
        }),
        linkPost: (post, website, published_date, category, author) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/post/link`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ post, website, published_date, category, author })
            })
            resolve()
        }),
        linkPostInTask: (post, website, published_date, category, author) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/tasks`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ post, website, published_date, category, author })
            })
            resolve()
        }),
        cancelSub: () => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/cancel-sub`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({})
            });
            resolve()
        }),
        successBuy: (session_id, hash) => new Promise(async () => {
            const requestHeaders = new Headers()
            requestHeaders.set('Content-Type', 'application/json')
            fetch(`${API_URL}/user/recover-checkout-session`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({
                    session_id,
                    hash
                })
            }).then(() => {
                window.location = '/'
            })
        }),
        buyUnit: (credits) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const stripe = await stripePromise;
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            requestHeaders.set('Content-Type', 'application/json')
            const response = await fetch(`${API_URL}/user/create-checkout-session-unit`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ credits })
            });
            const session = await response.json();
            const result = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (result.error) {
            }

            resolve()
        }),
        successPaymentUnit: (session_id) => new Promise(async () => {
            const requestHeaders = new Headers()
            requestHeaders.set('Content-Type', 'application/json')
            fetch(`${API_URL}/user/recover-checkout-session-unit`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({
                    session_id
                })
            })
                .then(response => response.json())
                .then((json) => {
                    window.location = `/`
                })
        }),
        websiteWordpressCategories: (website) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/website/wordpress/categories?${stringify({ website })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    data: json.data
                })
            })
        }),
        websiteWordpressUsers: (website) => new Promise(async (resolve) => {
            await authProvider.checkAuth()
            const url = `${API_URL}/user/website/wordpress/users?${stringify({ website })}`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    data: json.data
                })
            })
        }),
        postMissingTranslation: (field) => new Promise(async (resolve) => {
            const requestHeaders = new Headers()
            requestHeaders.set('Content-Type', 'application/json')
            await fetch(`${API_URL}/user/missingTranslation`, {
                headers: requestHeaders,
                method: 'POST',
                body: JSON.stringify({ field })
            });
            resolve()
        }),
        editProfile: (data) => new Promise(async (resolve) => {
            const requestHeaders = new Headers()
            requestHeaders.set('Content-Type', 'application/json')
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetch(`${API_URL}/user/profile`, {
                headers: requestHeaders,
                method: 'PUT',
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then((json) => {
                    resolve()
                })
        }),
        changePassword: (password, old_password) => {
            const requestHeaders = new Headers()
            requestHeaders.set("Content-Type", "application/json")
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetch(API_URL + "/user/change-password", {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify({
                    password,
                    old_password
                })
            }).then(response =>
                response.text().then(text => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                }))
            ).then(({ status, statusText, headers, body }) => {
                window.location.reload()
            });
        },
        indexmenow: (url) => new Promise(async (resolve) => {
            const requestHeaders = new Headers()
            requestHeaders.set("Content-Type", "application/json")
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetch(API_URL + "/user/indexmenow", {
                method: "POST",
                headers: requestHeaders,
                body: JSON.stringify({
                    url
                })
            }).then(response => resolve()
            )
        }),
        task: () => new Promise(async (resolve) => {
            const url = `${API_URL}/user/tasks`
            const requestHeaders = new Headers()
            requestHeaders.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
            fetchJson(url, { headers: requestHeaders }).then(json => {
                resolve({
                    data: json.data
                })
            })
        }),
    }
}

export default dataProvider
