/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Button, ListGroup, Tab, Nav } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";
// img
import dataProvider from '../../../dataProvider'
import { t } from '../../../t'

class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        this.html = `
<div><p>1 - Activer l'api des articles sur votre site web</p>
<p>2 - Installer notre plugin</p>
<p>Vous devez installer notre <a href="https://app.bradigo.fr/bradigo-1.0.0.zip" target="_blank" rel="noopener">Plugin</a></p>
<div class="entry-content">
<div id="tab-installation" class="plugin-installation section"></div>
</div>
<div class="entry-meta">
<div class="widget plugin-meta">
<p>3 - Configurer votre site web sur notre site</p>
<p>Mettre la clé api fournie par le plugin et tester la connexion</p>
<div class="entry-content">
<div id="tab-installation" class="plugin-installation section"></div>
</div>
<div class="entry-meta">
<div class="widget plugin-meta">Félicitation ! Votre site est maintenant connecté à votre compte</div>
</div>
</div>
</div>
<div class="entry-meta">
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
<div class="widget plugin-meta">&nbsp;</div>
</div></div>       
        `
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    <div dangerouslySetInnerHTML={{ __html: this.html }} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row >
                </Container >
            </>
        )
    }
}
export default Orderdetails;

