/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../../dataProvider'
import { t } from '../../../../t'

class Pricing1 extends Component {

   constructor(props) {
      super(props)
      this.state = {

      }
   }

   render() {

      return (
         <>
            <Container fluid>
               <Row>
                  <Col sm="12">
                     <Card>
                        <Card.Body>
                           <div className="table-responsive pricing pt-2">
                              <table id="my-table" className="table">
                                 <thead>
                                    <tr>
                                       <th className="text-center prc-wrap">
                                          <div className="prc-box">
                                             <div className="h3 pt-4">{t("1 Crédits ~ 1€")}<small></small>
                                             </div> <span className="type">{t("Buy Credits")}</span>
                                          </div>
                                       </th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr>
                                       <td className="text-center child-cell">
                                          <div style={{ width: "100%" }}>
                                             <div className="col-md-3 mb-3" style={{ display: "flex", margin: "auto" }}>
                                                <div style={{ marginRight: "10px" }}>
                                                   <OverlayTrigger placement="top" overlay={<Tooltip>{t("Nombre de crédits souhaités")}</Tooltip>} >
                                                      <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Credits Quantity"
                                                         value={this.state.credits || '0'} onChange={e => this.setState({ credits: Math.abs(Math.floor(e.target.value)) })} style={this.state.error && this.state.error.credits ? { border: "1px solid red" } : {}}
                                                      />
                                                   </OverlayTrigger>
                                                </div>
                                                <div style={{ marginLeft: "10px" }}>
                                                   <Form.Control type="text" disabled className="form-control" id="Text1" placeholder="0€"
                                                      value={((this.state.credits || 0) / 1) + '€'}
                                                   />
                                                </div>
                                             </div>
                                          </div>
                                       </td>
                                    </tr>
                                    <tr>
                                       <td className="text-center"> <Link className="btn btn-primary mt-3" disabled={!this.state.credits} onClick={() => dataProvider.customs.buyUnit(this.state.credits)}>{t("Purchase")}</Link>
                                       </td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </>
      )

   }
}


export default Pricing1;