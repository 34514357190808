/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../dataProvider'
import { t } from '../../../t'

const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: null,
            order: null,
            filterTitle: '',
            filterCategory: '',
            loading: false,
        }
    }

    componentDidMount() {
        dataProvider.customs.profile().then((response) => {
            this.setState({ profile: response.data })
        })
        this.call()
        setInterval(() => {
            this.call()
        }, 5000)
    }

    call() {
        dataProvider.getList('Cart', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { title: this.state.filterTitle, category: this.state.filterCategory } })
            .then(response => {
                if (!this.state.commit || this.state.commit !== response.commit) {
                    this.setState({ data: response.data, price: response.data.reduce((acc, v) => acc + v.price, 0), commit: response.commit })
                }
            })
    }

    submit() {
        this.setState({ loading: true }, () => {
            dataProvider.customs.buyCart().then((json) => {
                localStorage.setItem('notifications', JSON.stringify(json.noBought))
                window.location = '/order?tab=pills-home-tab-fill'
            })
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )
        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        return (
            <>
                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <Row>
                                <Col lg="12">
                                    <Card className="card-block card-stretch">
                                        <Card.Body className="p-0">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5 className="font-weight-bold">{t("Cart")}</h5>
                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table data-table mb-0">
                                                    <thead className="table-color-heading">
                                                        <tr className="text-light">
                                                            <th scope="col" >
                                                                <label className="text-muted m-0" >{t("Title")}</label>
                                                            </th>
                                                            <th scope="col" >
                                                                <label className="text-muted mb-0" >{t("Category")}</label>
                                                            </th>
                                                            <th scope="col" >
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Count words")}</Tooltip>} >
                                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Words")}</label>
                                                                </OverlayTrigger>
                                                            </th>
                                                            <th scope="col" >
                                                                <label className="text-muted mb-0" >{t("Price")}</label>
                                                            </th>
                                                            <th scope="col" >
                                                            </th>
                                                            <th scope="col" >
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.data.map((item) => (
                                                                <tr key={item.Price} className="white-space-no-wrap" style={{ ...(item.multicart ? { backgroundColor: "#ffdddd" } : {}) }}>
                                                                    <td className="">
                                                                        <div className="active-project-1 d-flex align-items-center mt-0 ">
                                                                            {/* <div className="h-avatar is-medium">
                                                                                <img className="avatar rounded" alt="user-icon" src={item.product} />
                                                                            </div> */}
                                                                            <div className="data-content">
                                                                                <div>
                                                                                    <span className="font-weight-bold">{item.title}</span>
                                                                                </div>
                                                                                {/* <p className="m-0 mt-1">
                                                                                    {item.des}
                                                                                </p> */}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{item.category}</td>
                                                                    <td>
                                                                        {item.count_words}
                                                                    </td>
                                                                    <td>
                                                                        <img style={{ width: "30px", marginRight: "10px" }} src="/coins.png" />
                                                                        {item.price}
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex justify-content-end align-items-center">
                                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Delete</Tooltip>} >
                                                                                <Link className="badge bg-danger" onClick={() => dataProvider.customs.deleteBuyPost(item.id).then(() => window.location.reload())}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                                    </svg>
                                                                                </Link>
                                                                            </OverlayTrigger>
                                                                        </div>
                                                                    </td>
                                                                    {
                                                                        item.multicart ? (
                                                                            <td style={{ color: "red" }}>
                                                                                {'⚠'}{' '}{t('Article présent dans un autre panier')}
                                                                            </td>
                                                                        ) : (
                                                                            <td></td>
                                                                        )
                                                                    }

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                        <Card.Body>
                                            <div className="d-flex justify-content-end mt-3">
                                                {/* <img style={{ width: "30px", height: "30px", marginRight: "10px" }} src="/coins.png" />
                                                {this.state.price} */}
                                                {
                                                    !this.state.loading && this.state.profile && this.state.price <= this.state.profile.credits && (
                                                        <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                            {t('Buy')} (TOTAL: {this.state.price})
                                                        </Button>
                                                    )
                                                }
                                                {
                                                    !this.state.loading && this.state.profile && this.state.price > this.state.profile.credits && (
                                                        <Button variant="btn btn-primary" onClick={() => window.location = '/pricing-1'}>
                                                            {t("Buy Credits")}
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
