/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../dataProvider'
import { t } from '../../../t'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            framework: 'WORDPRESS_5_0'
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("Website", { id }).then((res) => {
            this.setState({
                name: res.data.name,
                url: res.data.url,
                // framework: res.data.framework,
                wp_username: res.data.wp_username,
                wp_password: res.data.wp_password,
                wp_api_key: res.data.wp_api_key,
                wp_categories: res.data.wp_categories,
                wp_authors: res.data.wp_authors,
                connection: res.data.connection,
                createdAt: res.data.createdAt
            })
        })
    }

    submit() {
        this.testCo(() => {
            const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
            dataProvider.customs.websiteWordpressCategories(id)
            dataProvider.customs.websiteWordpressUsers(id)
            this.setState({ loading: true }, () => {
                const error = {}
                if (!this.state.name)
                    error.name = true
                if (!this.state.url)
                    error.url = true
                if (Object.keys(error).length === 0) {
                    dataProvider.update('Website', {
                        data: {
                            id,
                            name: this.state.name,
                            url: this.state.url,
                            framework: this.state.framework,
                            wp_username: this.state.wp_username,
                            wp_password: this.state.wp_password,
                            wp_api_key: this.state.wp_api_key,
                        }
                    }).then(() => window.location = '/website')
                } else {
                    this.setState({ error, loading: false })
                }
            })
        })
    }


    testCo(cb) {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            dataProvider.customs.testWebsiteWp(id).then((res) => {
                this.setState({ loading: false, testCo: res.result }, () => {
                    if (cb) {
                        cb()
                    }
                })
            })
        })
    }

    loadCat() {
        this.setState({ loading: true }, () => {
            const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
            dataProvider.customs.websiteWordpressCategories(id).then(() => {
                dataProvider.customs.websiteWordpressUsers(id).then(() => {
                    this.setState({ loading: false }, () => window.location.reload())
                })
            })
        })
    }

    testCo2() {
        // this.setState({ loading: true }, () => {
        //     dataProvider.customs.testWp(this.state.url, this.state.wp_api_key).then((res) => {
        //         this.setState({ loading: false, testCo: res.result }, () => {
        //             const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        //             dataProvider.update('Website', {
        //                 data: {
        //                     id,
        //                     wp_api_key: this.state.wp_api_key,
        //                 }
        //             })
        //         })
        //     })
        // })
        this.setState({ loading: true }, () => {
            const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
            dataProvider.update('Website', {
                data: {
                    id,
                    wp_api_key: this.state.wp_api_key,
                }
            }).then(() => {
                dataProvider.customs.testWebsiteWp(id).then((res) => {
                    this.setState({ loading: false, testCo: res.result })
                })
            })
        })
    }

    delete() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        this.setState({ loading: true }, () => {
            dataProvider.delete('Website', {
                id
            }).then(() => window.location = '/website')
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/website">{t("Websites")}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t("Edit Website")}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/website" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">{t("Back")}</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">{t("Edit Website")}</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        {/* <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Name *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Name" value={this.state.name || ''} onChange={e => this.setState({ name: e.target.value })} style={this.state.error && this.state.error.name ? { border: "1px solid red" } : {}} />
                                        </div> */}
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("URL")} *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" disabled placeholder={t("Search URL")} value={this.state.url || ''} onChange={e => this.setState({ url: e.target.value })} style={this.state.error && this.state.error.url ? { border: "1px solid red" } : {}} />
                                        </div>
                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    <Form className="row g-3">
                                        {/* <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text8" className="form-label font-weight-bold text-muted text-uppercase">Framework</Form.Label>
                                            <select value={this.state.framework} id="Text8" className="multipleSelect2  form-control choicesjs" onChange={e => this.setState({ framework: e.target.value })} >
                                                <option value=""></option>
                                                <option value="WORDPRESS_5_0">Wordpress v5.0 {'>'}=</option>
                                                <option value="OTHER">Other</option>
                                            </select>
                                        </div> */}
                                        {
                                            this.state.framework === 'WORDPRESS_5_0' && (
                                                // <OverlayTrigger placement="top" overlay={<Tooltip>{t("La clé API est automatiquement sauvegardé")}</Tooltip>} >
                                                <div className="col-md-6 mb-3">
                                                    <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("API KEY")}</Form.Label>
                                                    <Form.Control type="text" className="form-control" id="Text1" placeholder={t("Enter API KEY")} value={this.state.wp_api_key || ''} onChange={e => this.setState({ wp_api_key: e.target.value })} style={this.state.error && this.state.error.wp_api_key ? { border: "1px solid red" } : {}} />
                                                </div>
                                                // </OverlayTrigger>
                                            )
                                        }
                                        {/* {
                                            this.state.framework === 'WORDPRESS_5_0' && (
                                                <>
                                                    <div className="col-md-6 mb-3">
                                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Username</Form.Label>
                                                        <Form.Control type="text" className="form-control" id="Text1" placeholder={t("Enter Username")} value={this.state.wp_username || ''} onChange={e => this.setState({ wp_username: e.target.value })} style={this.state.error && this.state.error.wp_username ? { border: "1px solid red" } : {}} />
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">Password</Form.Label>
                                                        <Form.Control type="text" className="form-control" id="Text1" placeholder={t("Enter Password")} value={this.state.wp_password || ''} onChange={e => this.setState({ wp_password: e.target.value })} style={this.state.error && this.state.error.wp_password ? { border: "1px solid red" } : {}} />
                                                    </div>

                                                </>
                                            )
                                        } */}
                                        {
                                            !this.state.loading && this.state.framework && (
                                                <Button disabled={!(this.state.url && this.state.wp_api_key)} variant="btn btn-primary" style={{ marginRight: "20px", height: "50px", marginTop: "26px" }} onClick={() => this.testCo2()}>
                                                    {t("Test connection")}
                                                </Button>

                                            )
                                        }
                                        {
                                            !this.state.loading && this.state.framework && (
                                                (this.state.testCo === undefined && this.state.connection && this.state.connection.type === 'success') ||
                                                this.state.testCo
                                                ) && (
                                                    Date.now() - new Date(this.state.createdAt).getTime() > 24 * 3600 * 1000
                                                ) && (
                                                <Button disabled={!(this.state.url && this.state.wp_api_key)} variant="btn btn-primary" style={{ marginRight: "20px", height: "50px", marginTop: "26px" }} onClick={() => this.loadCat()}>
                                                    {t("Reload categories & author")}
                                                </Button>

                                            )
                                        }
                                        {/* {
                                            !this.state.loading && (
                                                <Button variant="btn btn-secondary" style={{ marginRight: "20px", height: "50px", marginTop: "26px" }} onClick={() => this.delete()}>
                                                    {t("Delete")}
                                                </Button>
                                            )
                                        }

                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" style={{ height: "50px", marginTop: "26px" }} onClick={() => this.submit()}>
                                                    {t("Update")}
                                                </Button>
                                            )
                                        } */}
                                        {
                                            this.state.loading && (
                                                <div style={{ display: "flex", height: "20px", marginTop: "38px" }}>
                                                    {t("Loading...")}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                                                    </svg>
                                                </div>
                                            )
                                        }

                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    {
                                        this.state.testCo === true && (
                                            <div className="col-md-12 mb-3" style={{ color: "green" }} >
                                                {t("Success connection")}
                                            </div>
                                        )
                                    }
                                    {
                                        this.state.testCo === false && (
                                            <div className="col-md-12 mb-3" style={{ color: "red" }} >
                                                {t("Error connection")}
                                            </div>
                                        )
                                    }
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                    </div>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {
                            this.state.wp_categories && (
                                <Col lg="4">
                                    <Card>
                                        <ListGroup as="ul" className="list-group-flush">
                                            <ListGroup.Item as="li" className="p-3">
                                                <h5 className="font-weight-bold pb-2">{t("Categories Found")}</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-borderless mb-0">
                                                        <tbody>
                                                            {
                                                                this.state.wp_categories.map(e => (
                                                                    <tr className="white-space-no-wrap">
                                                                        <td>
                                                                            {e.name}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            )
                        }
                        {
                            this.state.wp_authors && (
                                <Col lg="4">
                                    <Card>
                                        <ListGroup as="ul" className="list-group-flush">
                                            <ListGroup.Item as="li" className="p-3">
                                                <h5 className="font-weight-bold pb-2">{t("Authors Found")}</h5>
                                                <div className="table-responsive">
                                                    <table className="table table-borderless mb-0">
                                                        <tbody>
                                                            {
                                                                this.state.wp_authors.map(e => (
                                                                    <tr className="white-space-no-wrap">
                                                                        <td>
                                                                            {e.name}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;