/* TRANSLATED */

import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { getDarkMode } from '../../../store/mode'
//img
import logo from '../../../assets/images/logo.png'
import darklogo from '../../../assets/images/logo-dark.png'
import authProvider from '../../../authProvider';
import { t } from '../../../t'



function mapStateToProps(state) {
   return {
      darkMode: getDarkMode(state)
   };
}
const ConfirmMail = (props) => {
   const hash = Object.fromEntries((new URLSearchParams(props.location.search).entries())).hash
   console.log("hash", hash)
   setTimeout(() => {
      authProvider.validate(hash).then(() => window.location = '/auth/sign-in')
   }, 100)
   return (
      <>
         <section className="login-content">
            <Container fluid className="h-100">
               <Row className="align-items-center justify-content-center h-100">
                  <Col md="5" className="col-md-5">
                     <Card>
                        <Card.Body>
                           <Row className="align-items-center">
                              <Col lg="12" className="text-center">
                                 <h2 className="mt-3 mb-0">{t("Success !")}</h2>
                              </Col>
                           </Row>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </section>
      </>
   )
}


export default connect(mapStateToProps)(ConfirmMail)