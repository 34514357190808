/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Button, ListGroup, Tab, Nav, Form, InputGroup, Modal } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Chart from "react-apexcharts";
// img
import user1 from '../../../assets/images/user/1.jpg'
import dataProvider from '../../../dataProvider'
import { t } from '../../../t'



class MyVerticallyCenteredModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    checkDate() {
        const d = new Date(this.state.date)
        if (Date.now() - 24 * 3600 * 1000 <= d.getTime()) {
            this.setState({ ok: true })
        } else {
            this.setState({ ok: false })
        }
    }

    submit() {
        this.setState({ loading: true }, () => {
            dataProvider.customs.linkPostInTask(this.props.data.id, this.props.elem.id, this.state.date, this.state.category).then(() => window.location.reload())
        })
    }

    loadCategories() {
        dataProvider.customs.websiteWordpressCategories(this.props.elem.id).then(json => {
            this.setState({
                categories: json.data
            })
        })
    }

    render() {
        const props = this.props
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("Configurate Publication")}
                    </Modal.Title>
                </Modal.Header>
                <Card>
                    <Card.Body>
                        <Form className="row g-3">
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Publication Date")} *</Form.Label>
                                <Form.Control type="date" className="form-control" id="Text1" value={this.state.date || ''} onChange={e => this.setState({ date: e.target.value }, () => this.checkDate())} style={this.state.error && this.state.error.date ? { border: "1px solid red" } : {}} />
                            </div>
                        </Form>
                    </Card.Body>
                    {
                        (!this.state.categories && !this.props.elem.wp_categories) && (
                            <Card.Body>
                                <Button onClick={() => this.loadCategories()}>{t("Load categories From Website")}</Button>
                            </Card.Body>
                        )
                    }
                    {
                        (this.state.categories || this.props.elem.wp_categories) && (
                            <div className="col-md-6 mb-3">
                                <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Category")}</Form.Label>
                                <select className="form-control choicesjs" id="validationServer01" placeholder="Category2" onChange={e => this.setState({ category: e.target.value })} style={this.state.error && this.state.error.category ? { border: "1px solid red" } : {}}>
                                    <option value="" selected>{t("Select Category")}</option>
                                    {
                                        (this.state.categories || this.props.elem.wp_categories).map(e => <option value={e.id}>{e.name}</option>)
                                    }
                                </select>

                            </div>
                        )
                    }
                </Card>
                <Modal.Footer>
                    {
                        this.state.loading && (
                            <div style={{ display: "flex", height: "20px" }}>
                                {t("Loading...")}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clip-rule="evenodd" />
                                </svg>
                            </div>
                        )
                    }
                    {
                        !this.state.loading && (
                            <Button disabled={!this.state.ok} onClick={() => this.submit()}>{t("Submit")}</Button>
                        )
                    }
                    {
                        !this.state.loading && (
                            <Button onClick={props.onHide}>{t("Close")}</Button>
                        )
                    }

                </Modal.Footer>
            </Modal>
        );
    }
}

class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            indexEditPublished: -1
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("PostOrder", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
        dataProvider.getList('Website', { pagination: { page: 1, perPage: 10000 }, sort: { field: null, order: null }, filter: {} })
            .then(response => this.setState({ websites: response.data }))
    }

    renderStatus(elem) {
        if (!elem.framework) {
            return (
                <p className="mb-0 text-danger d-flex justify-content-start align-items-center">
                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
                    </svg>
                    {t("Not config")}
                </p>
            )
        }
        if (elem.framework !== 'WORDPRESS_5_0') {
            return (
                <p className="mb-0 text-danger d-flex justify-content-start align-items-center">
                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
                    </svg>
                    {t("Not supported")}
                </p>
            )
        }
        if (elem.framework === 'WORDPRESS_5_0' && (!elem.wp_api_key)) {
            return (
                <p className="mb-0 text-danger d-flex justify-content-start align-items-center">
                    <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                        <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
                    </svg>
                    {t("Missing Cred")}
                </p>
            )
        }
        if (elem.framework === 'WORDPRESS_5_0' && elem.wp_api_key) {
            if (this.state.data.websites[elem.id]) {
                if (this.state.data.websites[elem.id].wordpress_post_id) {
                    return (
                        <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                            <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle>
                            </svg>
                            {t("Linked")}
                        </p>
                    )
                } else {
                    if (new Date(this.state.data.websites[elem.id].published_date).getTime() > Date.now()) {
                        return (
                            <p className="mb-0 text-success d-flex justify-content-start align-items-center">
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle>
                                </svg>
                                {t("Intended")}
                            </p>
                        )
                    } else {
                        return (
                            <p className="mb-0 text-warning d-flex justify-content-start align-items-center">
                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12" cy="12" r="8" fill="orange"></circle>
                                </svg>
                                {t("Failed Link")}
                            </p>
                        )
                    }
                }
            } else {
                return (
                    <p className="mb-0 text-danger d-flex justify-content-start align-items-center">
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                            <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle >
                        </svg >
                        {t("Not link")}
                    </p >
                )
            }
        }
    }

    renderAction(elem, link) {
        if (!elem.framework) {
            return (
                <div className="d-flex justify-content-end align-items-center">
                    <Link to={"/website-edit?id=" + elem.id} className="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                    </Link>
                </div>
            )
        }
        if (elem.framework !== 'WORDPRESS_5_0') {
            return (
                <></>
            )
        }
        if (elem.framework === 'WORDPRESS_5_0' && (!elem.wp_api_key)) {
            return (
                <Link to={"/website-edit?id=" + elem.id} className="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                </Link>
            )
        }
        if (elem.framework === 'WORDPRESS_5_0' && elem.wp_api_key) {
            if (this.state.data.websites[elem.id]) {
                if (this.state.data.websites[elem.id].wordpress_post_id) {
                    return (
                        <></>
                    )
                } else {
                    if (new Date(this.state.data.websites[elem.id].published_date).getTime() > Date.now()) {
                        return (
                            <></>
                        )
                    } else {
                        return (
                            <div className="d-flex justify-content-end align-items-center">
                                <div style={{ cursor: "pointer", color: "orange" }} onClick={() => link()} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                                    </svg>
                                </div>
                            </div>
                        )
                    }
                }
            } else {
                return (
                    <div className="d-flex justify-content-end align-items-center">
                        <div style={{ cursor: "pointer", color: "#3378FF" }} onClick={() => link()} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                            </svg>
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <MyVerticallyCenteredModal
                    data={this.state.data}
                    elem={this.state.indexEditPublished > -1 && this.state.websites[this.state.indexEditPublished]}
                    show={this.state.indexEditPublished !== -1}
                    onHide={() => this.setState({ indexEditPublished: -1 })}
                />
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/order">{t("Posts")}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t("Post View")}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/order" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">{t("Back")}</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">{t("Post View")}</h4>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="4">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">{t("Title")}</p>
                                                    </td>
                                                    <td>
                                                        <h6>{this.state.data.title}</h6>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">{t("Category")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.category}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">{t("Count Word")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.count_words}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">{t("Spam Score")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.spam_score}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">{t("Score YTG")}</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.scoreYTG}</p>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">NextLevel</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.score_NextLevel ? '✅' : '❌'}</p>
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Linksgarden</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.score_Linksgarden ? '✅' : '❌'}</p>
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Discover</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.score_Discover ? '✅' : '❌'}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="p-0">
                                                        <p className="mb-0 text-muted">Amazon</p>
                                                    </td>
                                                    <td>
                                                        <p className="mb-0 ">{this.state.data.score_Amazon ? '✅' : '❌'}</p>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card>
                                <Card.Body className="p-0">
                                    <Tab.Container defaultActiveKey="second">
                                        <Nav as="ul" variant="nav tab-nav-pane nav-tabs pt-2 mb-0">
                                            {/* <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="first" bsPrefix="font-weight-bold text-uppercase px-5 py-2 " >{t("Websites")}</Nav.Link></Nav.Item> */}
                                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="second" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >{t("Content")}</Nav.Link></Nav.Item>
                                            <Nav.Item as="li" className="pb-2 mb-0 nav-item"><Nav.Link data-toggle="tab" eventKey="3" bsPrefix="font-weight-bold text-uppercase px-5 py-2" >{t("Preview")}</Nav.Link></Nav.Item>
                                        </Nav>

                                        <Tab.Content>
                                            <Tab.Pane eventKey="first" className=" fade show  ">
                                                <div>
                                                    <div className="d-flex justify-content-between align-items-center p-3">
                                                        <h5>{t("Website List")}</h5>
                                                    </div>
                                                    {
                                                        this.state.websites && (
                                                            <div className="table-responsive">
                                                                <table className="table data-table mb-0">
                                                                    <thead className="table-color-heading">
                                                                        <tr className="text-muted">
                                                                            <th scope="col">{t("Name")}</th>
                                                                            <th scope="col">{t("URL")}</th>
                                                                            <th scope="col">{t("Status")}</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            this.state.websites.map((elem, index) => (
                                                                                <tr>
                                                                                    <td>{elem.name}</td>
                                                                                    <td><a href={elem.url}>{elem.url}</a></td>
                                                                                    <td>{this.renderStatus(elem)}</td>
                                                                                    <td>{this.renderAction(elem, () => this.setState({ indexEditPublished: index }))}</td>
                                                                                </tr>
                                                                            ))
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second" className="  fade p-3">
                                                <div dangerouslySetInnerHTML={{ __html: this.state.data.content }} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="3" className="  fade p-3">
                                                <div style={{ padding: "10px", maxWidth: "100%" }}>
                                                    <img style={{ width: "100%" }} src={this.state.data.preview_image} />
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </Card.Body>
                            </Card>
                            <Card.Body className="p-0">
                                {t("How to link your site to webapp ?")} <a href='/tuto'>{t("Click here")}</a>
                            </Card.Body>
                        </Col>
                    </Row >
                </Container >
            </>
        )
    }
}
export default Orderdetails;