/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../dataProvider'
import { t } from '../../../t'


class Productadd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    submit() {
        this.setState({ loading: true }, () => {
            const error = {}
            if (!this.state.name)
                error.name = true
            if (!this.state.url)
                error.url = true
            if (Object.keys(error).length === 0) {
                dataProvider.create('Website', {
                    data: {
                        name: this.state.name,
                        url: this.state.url,
                    }
                }).then(() => window.location = '/website')
            } else {
                this.setState({ error })
            }
        })
    }

    render() {
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/website">{t("Websites")}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t("Add Website")}</li>
                                        </ol>
                                    </nav>
                                </div>
                                <Link to="/product" className="btn btn-primary btn-sm d-flex align-items-center justify-content-between ml-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                    </svg>
                                    <span className="ml-2">{t("Back")}</span>
                                </Link>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3 d-flex justify-content-between">
                            <h4 className="font-weight-bold d-flex align-items-center">{t("New Website")}</h4>
                        </Col>
                        <Col lg="12">
                            <Card>
                                <Card.Body>
                                    {/* <h5 className="font-weight-bold mb-3">Basic Information</h5> */}
                                    <Form className="row g-3">
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("Name")} *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder="Enter Name" onChange={e => this.setState({ name: e.target.value })} style={this.state.error && this.state.error.name ? { border: "1px solid red" } : {}} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <Form.Label htmlFor="Text1" className="form-label font-weight-bold text-muted text-uppercase">{t("URL")} *</Form.Label>
                                            <Form.Control type="text" className="form-control" id="Text1" placeholder={t("Search URL")} onChange={e => this.setState({ url: e.target.value })} style={this.state.error && this.state.error.url ? { border: "1px solid red" } : {}} />
                                        </div>
                                    </Form>
                                </Card.Body>
                                <Card.Body>
                                    <div className="d-flex justify-content-end mt-3">
                                        {
                                            !this.state.loading && (
                                                <Button variant="btn btn-primary" onClick={() => this.submit()}>
                                                    {t("Create Website")}
                                                </Button>
                                            )
                                        }
                                    </div>
                                </Card.Body>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Productadd;