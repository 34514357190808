/* TRANSLATED */

import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Accordion, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Scrollbar from 'smooth-scrollbar'
import { connect } from "react-redux";
import { getDarkMode } from '../../../../store/mode'
import { t } from '../../../../t'

//img
import logo from '../../../../../src/assets/images/logo.png'
import { getState, setCallback } from '../../../../store/customs';

function mapStateToProps(state) {
    return {
        darkMode: getDarkMode(state)
    };
}


const minisidbar = () => {
    document.body.classList.toggle('sidebar-main')
}


const SidebarStyle = (props) => {

    //location
    let location = useLocation();

    const urlParams = new URLSearchParams(window.location.search);
    const sidebar = urlParams.get('sidebar');
    var variant = '';
    if (sidebar !== null) {
        variant = '';
        switch (sidebar) {
            case "0":
                variant = 'sidebar-dark';
                break;
            case "1":
                variant = 'sidebar-light';
                break;
            default:
                variant = '';
                break;
        }
    }

    // Collapse state
    const [activeMenu, setActiveMenu] = useState(false)
    const [activesubMenu, setSubmenu] = useState(false)
    useEffect(
        () => {
            Scrollbar.init(document.querySelector('#my-scrollbar'))
        }
    )

    const [sidebarToggle, setSidebarToggle] = useState(0)

    setCallback((state) => {
        setSidebarToggle(state.sidebarToggle)
    })
    return (
        <>
            <div className={`iq-sidebar sidebar-default ${variant}`}>
                <div className="iq-sidebar-logo d-flex align-items-end justify-content-between">
                    <Link to="/" className="header-logo" style={{ width: "100%" }}>
                        {
                            !getState().sidebarToggle ? (
                                <img src={"/logo.png"} style={{ margin: "auto" }} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                            ) : (
                                <img src={"/favicon.png"} style={{ margin: "auto" }} className={`img-fluid rounded-normal light-logo ${props.darkMode ? 'd-none' : ''}`} alt="logo" />
                            )
                        }
                    </Link>
                    <div className="side-menu-bt-sidebar-1">
                        <svg onClick={minisidbar} xmlns="http://www.w3.org/2000/svg" className="text-light wrapper-menu" width="30" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div>
                <div className="data-scrollbar" data-scroll="1" id="my-scrollbar">
                    <nav className="iq-sidebar-menu">
                        <Accordion as="ul" id="iq-sidebar-toggle" className="side-menu" onSelect={(e) => setActiveMenu(e)}>
                            <li className={`${location.pathname === '/' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">{t("Textes en vente")}</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/website' ? 'active' : ''}  sidebar-layout`}>
                                <Link to="/website" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                        </svg>
                                    </i><span className="ml-2">{t("Website")}</span>
                                </Link>
                            </li>
                            <li className={`${location.pathname === '/order' ? 'active' : ''}  sidebar-layout`} >
                                <Link to="/order" className="svg-icon">
                                    <i className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </i>
                                    <span className="ml-2">{t("Order")}</span>
                                </Link>
                            </li>
                        </Accordion>
                    </nav>
                    <div className="pt-5 pb-5"></div>
                </div>
                {
                    sidebarToggle === 0 && (
                        <>
                            <div style={{ position: "absolute", bottom: "200px", left: "45px" }}>
                                <Button onClick={() => window.location = '/pricing-1'} className="svg-icon">
                                    <i className="">
                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg> */}
                                        <img style={{ width: "30px" }} src="/coins.png" />
                                    </i><span className="ml-2">{t("Buy Credits")}</span>
                                </Button>
                            </div>
                            <div style={{ position: "absolute", bottom: "0px", left: "15px", color: 'white' }}>
                                <ul>
                                    <li style={{ cursor: "pointer" }} >
                                        <a href="https://bradigo.fr/contact">
                                            <span style={{ color: "white" }} className="ml-2">Contact</span>
                                        </a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} >
                                        <a href="https://bradigo.fr/cgv">
                                            <span style={{ color: "white" }} className="ml-2">CGV</span>
                                        </a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} >
                                        <a href="https://bradigo.fr/mentions-legales">
                                            <span style={{ color: "white" }} className="ml-2">Mentions légales</span>
                                        </a>
                                    </li>
                                    <li style={{ cursor: "pointer" }} >
                                        <a href="https://bradigo.fr/faq">
                                            <span style={{ color: "white" }} className="ml-2">FAQs</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}

// export default SidebarStyle;
export default connect(mapStateToProps)(SidebarStyle)