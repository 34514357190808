/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, Form, Button, OverlayTrigger, Tab, Nav, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'

import dataProvider from '../../../dataProvider'

import { setCart, getState } from '../../../store/customs'
import { t } from '../../../t'

const formatDate = (d) => {
    d = d.split('T')[0]
    const arr = d.split('-')
    return `${arr[2]}/${arr[1]}/${arr[0]}`
}

class Product extends Component {

    constructor(props) {
        super(props)

        this.state = {
            limit: 1000,
            page: 1,
            field: 'createdAt',
            order: 'desc',
            filterTitle: '',
            filterCategory: '',
            loadingBuy: false,
        }

        this.categories = [
            "Animaux",
            "Art & Culture",
            "Avocat & Juridique",
            "Beauté",
            "Code Promo",
            "Croyance & Voyance",
            "Entreprise",
            "Fêtes & Cérémonies",
            "Finance & Banque",
            "Cuisine & Gastronomie",
            "Généraliste",
            "Écologie",
            "Hightech & Informatique",
            "Immo & Démenagement",
            "Jeux d'argent",
            "Loisir créatif",
            "Maison & Jardin",
            "Mode",
            "Parentalité",
            "Rencontre",
            "Sexe",
            "Retraite",
            "Santé",
            "Science",
            "Sport & Loisir",
            "Tabac",
            "Transport",
            "Travaux & Dépannage",
            "Voyage & Hôtellerie",
            "Non classé",
        ]
    }

    componentDidMount() {
        this.call()
        dataProvider.getList('Partner', { pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: { title: this.state.filterTitle, category: this.state.filterCategory, state: this.state.filterState } })
            .then(response => this.setState({ partners: response.data.reduce((acc, v) => ({ ...acc, [v.id]: v }), {}) }))
    }

    call() {
        dataProvider.getList('Post', {
            pagination: { page: this.state.page, perPage: this.state.limit }, sort: { field: this.state.field, order: this.state.order }, filter: {
                title: this.state.filterTitle, category: this.state.filterCategory,
                score_NextLevel: this.state.score_NextLevel,
                // score_Linksgarden: this.state.score_Linksgarden,
                score_Discover: this.state.score_Discover,
                score_Amazon: this.state.score_Amazon,
                has_image: this.state.has_image,

            }
        })
            .then(response => this.setState({ data: response.data }))
    }

    buy(item) {
        item.buy = 4
        this.setState({ loadingBuy: true }, () => {
            setTimeout(() => dataProvider.customs.buyPost(item.id).then(() => {
                item.buy = 3;
                this.setState({ loadingBuy: false }, () => {
                    setCart(getState().cart + 1)
                })
            }), 1000)
        })
    }

    renderTr(item) {
        return (
            <tr key={item.Price} >
                <td>
                    {
                        item.contentrank ? (
                            <OverlayTrigger placement="top" overlay={<Tooltip>{"Content Rank"}</Tooltip>} >
                                <img style={{ width: "50px", height: "50px", borderRadius: "25px" }} src="content-rank-logo.png" />
                            </OverlayTrigger>
                        ) : (
                            item.partner ? (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{this.state.partners[item.partner].name}</Tooltip>} >
                                    <img style={{ width: "50px", height: "50px", borderRadius: "25px" }} src={this.state.partners[item.partner].avatar} />
                                </OverlayTrigger>
                            ) : "Bradigo"
                        )
                    }
                </td>
                <td className="">
                    <div className="active-project-1 d-flex align-items-center mt-0 ">
                        <div className="data-content">
                            <div className="font-weight-bold" style={{ width: "400px", wordWrap: "break-word" }}>
                                {item.title}
                            </div>
                        </div>
                    </div>
                </td>
                <td><div style={{ cursor: "pointer" }} onClick={() => this.setState({ filterCategory: item.category === this.state.filterCategory ? '' : item.category }, () => this.call())}>{item.category}</div></td>
                <td>
                    {item.count_words}
                </td>

                {/* <td>
                    {item.spam_score}
                </td>
                <td>
                    {item.scoreYTG}
                </td> */}
                <td>
                    <div>
                        <OverlayTrigger placement="top" overlay={<Tooltip>{t("Score d'optimisiation & Danger SEO")}</Tooltip>} >
                            {
                                item.scoreYTG ?
                                    <span style={{ position: 'relative' }}>
                                        <img style={{ width: "98px" }} src='/YourTextGuru.png' />
                                        <div style={{ position: "absolute", top: "1px", left: "10px" }}>{item.scoreYTG}</div>
                                        <div style={{ position: "absolute", top: "1px", right: "10px" }}>{item.spam_score}</div>
                                    </span>
                                    :
                                    <span style={{ position: 'relative' }}>
                                        <img style={{ width: "98px" }} src='/YourTextGuru_off.png' />
                                    </span>
                            }
                        </OverlayTrigger>
                        {' '}
                        {item.score_NextLevel && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur NextLevel")}</Tooltip>} ><span onClick={() => this.setState({ score_NextLevel: this.state.score_NextLevel ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/NextLevelLink.png' /></span></OverlayTrigger>}
                        {!item.score_NextLevel && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur NextLevel")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/NextLevelLink_off.png' /></span></OverlayTrigger>}
                        {' '}
                        {/* {item.score_Linksgarden && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur Linksgarden")}</Tooltip>} ><span onClick={() => this.setState({ score_Linksgarden: this.state.score_Linksgarden ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Linksgarden.png' /></span></OverlayTrigger>}
                        {!item.score_Linksgarden && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Répond a un besoin sur Linksgarden")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Linksgarden_off.png' /></span></OverlayTrigger>}
                        {' '} */}
                        {item.score_Discover && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Optimisé pour Discover")}</Tooltip>} ><span onClick={() => this.setState({ score_Discover: this.state.score_Discover ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Discover.png' /></span></OverlayTrigger>}
                        {!item.score_Discover && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Optimisé pour Discover")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Discover_off.png' /></span></OverlayTrigger>}
                        {' '}
                        {item.score_Amazon && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Adapté pour Amazon")}</Tooltip>} ><span onClick={() => this.setState({ score_Amazon: this.state.score_Amazon ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Amazon.png' /></span></OverlayTrigger>}
                        {!item.score_Amazon && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Adapté pour Amazon")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Amazon_off.png' /></span></OverlayTrigger>}
                        {' '}
                        {item.has_image && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Contient minimum 1 image")}</Tooltip>} ><span onClick={() => this.setState({ has_image: this.state.has_image ? undefined : 1 }, () => this.call())}><img style={{ width: "25px" }} src='/Image.png' /></span></OverlayTrigger>}
                        {!item.has_image && <OverlayTrigger placement="top" overlay={<Tooltip>{t("Contient minimum 1 image")}</Tooltip>} ><span><img style={{ width: "25px" }} src='/Image_off.png' /></span></OverlayTrigger>}
                    </div>
                </td>


                <td>
                    <img style={{ width: "25px", marginRight: "10px" }} src="/coins.png" />
                    {item.price}
                </td>
                {/* <td>
                    {formatDate(item.createdAt)}
                </td> */}
                <td>
                    <div className="d-flex justify-content-end align-items-center">
                        {
                            item.buy === 0 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Buy")}</Tooltip>} >
                                    <div className="" style={{ cursor: "pointer", color: "#3378FF" }} onClick={() => this.buy(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            )
                        }
                        {
                            item.buy === 1 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("View")}</Tooltip>} >
                                    <Link className="" to="/pricing-1" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </Link>
                                </OverlayTrigger>
                            )
                        }
                        {
                            item.buy === 2 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("View")}</Tooltip>} >
                                    <Link className="" to={"/order-view?id=" + item.id} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </Link>
                                </OverlayTrigger>
                            )
                        }
                        {
                            item.buy === 3 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("View")}</Tooltip>} >
                                    <Link className="" to={"/cart-view"} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg>
                                    </Link>
                                </OverlayTrigger>
                            )
                        }
                        {
                            this.state.loadingBuy && item.buy === 4 && (
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                            )
                        }
                        {
                            item.buy === 5 && (
                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Buy")}</Tooltip>} >
                                    <div className="" style={{ cursor: "pointer", color: "#3378FF" }} onClick={() => this.buyContentRank(item)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                                        </svg>
                                    </div>
                                </OverlayTrigger>
                            )
                        }

                    </div>
                </td>
            </tr>
        )
    }

    renderList(filter) {
        if (!this.state.data || !this.state.partners)
            return <></>
        const up = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
            </svg>
        )
        const down = (
            <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
            </svg>
        )

        const data = this.state.data.filter(filter)

        const sort = (source) => () => this.state.field !== source ? this.setState({ field: source, order: 'desc' }, () => this.call()) : this.setState({ order: this.state.order === 'desc' ? 'asc' : 'desc' }, () => this.call())

        const availableCats = data.map(e => e.category)
        return (
            <>
                <Container fluid>
                    <Row>

                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between my-schedule mb-4">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h4 className="font-weight-bold">{t("Marketplace de contenu")}</h4>
                                </div>
                                <div className="create-workform">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                                        <div className="modal-product-search d-flex">
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0">
                                                    <Form.Control type="text" className="form-control" id="exampleInputText" placeholder={t("Search Product")} onChange={(e) => this.setState({ filterTitle: e.target.value }, () => this.call())} />
                                                    {/* <Link className="search-link" to="#">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                                        </svg>
                                                    </Link> */}
                                                </div>
                                            </Form>
                                            <Form className="mr-3 position-relative">
                                                <div className="form-group mb-0" style={{ display: "flex", cursor: "pointer" }} >
                                                    {
                                                        this.state.filterCategory && (
                                                            <div style={{ paddingTop: "8px" }} onClick={() => this.setState({ filterCategory: '' }, () => this.call())} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                </svg>
                                                            </div>
                                                        )
                                                    }
                                                    <select className="form-control choicesjs" id="validationServer01" placeholder="Category" value={this.state.filterCategory} onChange={(e) => this.setState({ filterCategory: e.target.value }, () => this.call())} >
                                                        <option value="" selected>{t("Select Category")}</option>
                                                        {
                                                            this.categories.filter(e => availableCats.includes(e)).map(e => <option value={e}>{e}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </Form>
                                            {/* <Link to="/product-add" className="btn btn-primary position-relative d-flex align-items-center justify-content-between">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="mr-2" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    Add Product
                                </Link> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col lg="12">
                                    <Card className="card-block card-stretch">
                                        <Card.Body className="p-0">
                                            <div className="d-flex justify-content-between align-items-center p-3">
                                                <h5 className="font-weight-bold">{t("Liste des textes en ventes")}</h5>
                                                {/* <Button variant="btn btn-secondary btn-sm">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="mr-1" width="20" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                        </svg>
                                        Export
                                    </Button> */}
                                            </div>
                                            <div className="table-responsive cadre-table-scroll" >
                                                <table className="data-table mb-0 table-scroll">
                                                    <thead className="table-color-heading" style={{ position: "relative", zIndex: 1 }}>
                                                        <tr className="text-light">
                                                            <th scope="col">
                                                                <label className="text-muted m-0" >{t("Partner")}</label>
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer", position: "sticky", top: 0 }} onClick={sort("title")}>
                                                                <label className="text-muted m-0" style={{ cursor: "pointer" }} >{t("Title")}</label>
                                                                {' '}
                                                                {this.state.field === "title" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "title" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            <th scope="col">
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer", position: "sticky", top: 0 }} >{t("Category")}</label>
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("count_words")}>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>{t("Count words")}</Tooltip>} >
                                                                    <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Words")}</label>
                                                                </OverlayTrigger>
                                                                {' '}
                                                                {this.state.field === "count_words" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "count_words" && this.state.order === 'asc' && (up)}
                                                            </th>


                                                            {/* <th scope="col" style={{ cursor: "pointer" }} onClick={sort("spam_score")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Spam Score</label>
                                                                {' '}
                                                                {this.state.field === "spam_score" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "spam_score" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("scoreYTG")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >Score YTG</label>
                                                                {' '}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'asc' && (up)}
                                                            </th> */}
                                                            <th scope="col" style={{ cursor: "pointer", textAlign: "center" }} onClick={sort("scoreYTG")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Caractéristique")}</label>
                                                                {' '}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "scoreYTG" && this.state.order === 'asc' && (up)}
                                                            </th>



                                                            <th scope="col" style={{ cursor: "pointer" }} onClick={sort("price")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Price")}</label>
                                                                {' '}
                                                                {this.state.field === "price" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "price" && this.state.order === 'asc' && (up)}
                                                            </th>
                                                            {/* <th scope="col" style={{ cursor: "pointer" }} onClick={sort("createdAt")}>
                                                                <label className="text-muted mb-0" style={{ cursor: "pointer" }} >{t("Date")}</label>
                                                                {' '}
                                                                {this.state.field === "createdAt" && this.state.order === 'desc' && (down)}
                                                                {this.state.field === "createdAt" && this.state.order === 'asc' && (up)}
                                                            </th> */}
                                                            <th scope="col">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.map((item) => this.renderTr(item))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }

    render() {
        if (!this.state.data || !this.state.partners)
            return <></>
        const tab = Object.fromEntries((new URLSearchParams(window.location.search).entries())).tab

        return (
            <>
                <Container fluid>
                    <Row>
                        <Card.Body className="p-0">
                            <Tab.Container defaultActiveKey={tab || "pills-contact-tab-fill"}>
                                <Nav variant="pills mb-3" fill>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-contact-tab-fill" onClick={() => this.setState({ key: 0 })}>
                                            <img src='/100_Humain.png' style={{ width: "40px" }} />
                                            {t("100% Human")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-profile-tab-fill" onClick={() => this.setState({ key: 1 })}>
                                            <img src='/50_50.png' style={{ width: "40px" }} />
                                            {t("50% Human - 50% Robot")}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pills-home-tab-fill" onClick={() => this.setState({ key: 2 })}>
                                            <img src='/100_AI.png' style={{ width: "40px" }} />
                                            {t("100% Robot")}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="pills-contact-tab-fill" >
                                        {/* {this.renderList((elem) => elem.list_status === "published", false, false, true, true)} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pills-profile-tab-fill">
                                        {/* {this.renderList((elem) => elem.list_status === "intented", false, true, true)} */}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="pills-home-tab-fill" >
                                        {/* {this.renderList((elem) => !elem.list_status, true)} */}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>

                        </Card.Body>
                        {
                            !this.state.key && (
                                this.renderList((elem) => elem.tag_human_robot === "human")
                            )
                        }
                        {
                            this.state.key === 1 && (
                                this.renderList((elem) => elem.tag_human_robot === "human-robot")
                            )
                        }
                        {
                            this.state.key === 2 && (
                                this.renderList((elem) => elem.tag_human_robot === 'robot')
                            )
                        }
                    </Row>
                </Container>
            </>
        )
    }
}

export default Product;
