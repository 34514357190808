/* TRANSLATED */

import React, { Component } from 'react'
import { Container, Row, Col, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import dataProvider from '../../../dataProvider'
import { t } from '../../../t'


// img
import product1 from '../../../assets/images/products/1.jpg'
import product6 from '../../../assets/images/products/6.jpg'
import product8 from '../../../assets/images/products/8.jpg'


class Orderdetails extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        const id = Object.fromEntries((new URLSearchParams(window.location.search).entries())).id
        dataProvider.getOne("Website", { id }).then((res) => {
            this.setState({
                data: res.data
            })
        })
    }

    render() {
        if (!this.state.data)
            return <></>
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb p-0 mb-0">
                                            <li className="breadcrumb-item"><Link to="/website">{t("Website")}</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page">{t("Website Details")}</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </Col>
                        <Col lg="12" className="mb-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="font-weight-bold">{t("Website Details")}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4">
                            <Card>
                                <ListGroup as="ul" className="list-group-flush">
                                    <ListGroup.Item as="li" className="p-3">
                                        <h5 className="font-weight-bold pb-2">{t("Website Info")}</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            {t("Name")}
                                                        </td>
                                                        <td>
                                                            {this.state.data.name}
                                                        </td>
                                                    </tr>
                                                    <tr className="white-space-no-wrap">
                                                        <td className="text-muted pl-0">
                                                            {t("Url")}
                                                        </td>
                                                        <td>
                                                            <a href={this.state.data.url}>{this.state.data.url}</a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </ListGroup.Item>
                                </ListGroup>
                            </Card>
                        </Col>
                        <Col lg="8">
                            <Card>
                                <Card.Body className="p-0">
                                    <div>
                                        <div className="d-flex justify-content-between align-items-center p-3">
                                            <h5>{t("Posts List")}</h5>
                                        </div>
                                        {
                                            this.state.data.posts && (
                                                <div className="table-responsive">
                                                    <table className="table data-table mb-0">
                                                        <thead className="table-color-heading">
                                                            <tr className="text-muted">
                                                                <th scope="col">{t("Title")}</th>
                                                                <th scope="col">{t("Published Date")}</th>
                                                                <th scope="col">{t("Status")}</th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.data.posts.map((elem, index) => (
                                                                    <tr>
                                                                        <td>{elem.title}</td>
                                                                        <td>{elem.published_date && elem.published_date.split('T')[0]}</td>
                                                                        <td style={elem.status.type === 'success' ? { color: 'green' } : (elem.status.type === 'error' ? { color: 'red' } : {})}>
                                                                            {elem.status.type === 'success' && (
                                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                    <circle cx="12" cy="12" r="8" fill="#3cb72c"></circle>
                                                                                </svg>
                                                                            )}
                                                                            {elem.status.type === 'error' && (
                                                                                <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" viewBox="0 0 24 24" fill="none">
                                                                                    <circle cx="12" cy="12" r="8" fill="#F42B3D"></circle>
                                                                                </svg>
                                                                            )}
                                                                            {elem.status.message}
                                                                        </td>
                                                                        <td>
                                                                            <div className="d-flex justify-content-end align-items-center">
                                                                                <OverlayTrigger placement="top" overlay={<Tooltip>View</Tooltip>} >
                                                                                    <Link to={"/order-view?id=" + elem.id} className="">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                                                        </svg>
                                                                                    </Link>
                                                                                </OverlayTrigger>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )
                                        }
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default Orderdetails;